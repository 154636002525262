import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';

import { ROLES } from '../../types/users';
import { StreamingDevice } from '../../types/streamingDevices';
import { selectCurrentUser } from '../../store/user/selectors';
import {
  useDeleteStreamingDeviceMutation,
  useEditStreamingDeviceMutation,
  useStreamingDevicesQuery
} from '../../store/services/streamingDevicesApi';
import { handleCatch } from '../../store/services/helpers';
import ROUTES from '../../routes.constants';
import useTitle from '../../helpers/useTitle';
import ConfirmModal from '../../components/ConfirmModal';
import { Sort } from '../../components/CNCTable/helpers';
import CNCTable from '../../components/CNCTable';
import Button from '../../components/Button';

import { OnFieldChangeParams, streamingDevicesColumns } from './StreamingDevices.table';

import './StreamingDevices.scss';

const PAGE_SIZE = 10;

const StreamingDevices = () => {
  const user = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  useTitle('Streaming Devices');

  const streamingDevicesRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [currentPage, setCurrentPage] = useState<number>(0);
  const [changing, setChanging] = useState<{ id: string; key: keyof StreamingDevice }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [sortField, setSortField] = useState<string | undefined>();
  const [sortDirection, setSortDirection] = useState<'ASC' | 'DESC' | undefined>();

  const sortingQueryParam =
    sortField && sortDirection ? `${sortField}:${sortDirection}` : undefined;

  const {
    data: allDevices,
    isFetching,
    refetch
  } = useStreamingDevicesQuery({
    page_size: PAGE_SIZE,
    page: currentPage,
    sort: sortingQueryParam
  });

  const [editStreamingDevice] = useEditStreamingDeviceMutation();
  const [deleteStreamingDevice] = useDeleteStreamingDeviceMutation();

  useEffect(() => {
    const handleScroll = () => {
      if (!streamingDevicesRef.current || !buttonRef.current) return;

      const { bottom } = streamingDevicesRef.current.getBoundingClientRect();
      const buttonHeight = buttonRef.current.offsetHeight;

      const offsetBottom = 20;

      if (bottom <= window.innerHeight - offsetBottom - buttonHeight) {
        buttonRef.current.style.bottom = '3rem';
        buttonRef.current.style.position = 'absolute';
      } else {
        buttonRef.current.style.position = 'fixed';
        buttonRef.current.style.bottom = '2rem';
      }
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleOpenModal = (id: string) => {
    setIsModalOpen(true);
    setSelectedId(id);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedId(undefined);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleSortChange = (sort: Sort<StreamingDevice>) => {
    setSortField(sort.key);
    setSortDirection(sort.dir);
    setCurrentPage(0);
  };

  const onDelete = async () => {
    if (selectedId) {
      try {
        setLoading(true);
        await deleteStreamingDevice({ id: selectedId }).unwrap;

        setTimeout(() => {
          refetch();
          setLoading(false);
        }, 3000);

        setSelectedId(undefined);
        setIsModalOpen(false);
      } catch (e) {
        handleCatch(e);
      }
    }
  };

  const onFieldChange = async ({ record, key, value }: OnFieldChangeParams) => {
    try {
      setChanging({ id: record.id, key });
      await editStreamingDevice({ id: record.id, [key]: value, shouldInvalidate: false }).unwrap();

      setTimeout(() => {
        refetch();
        setChanging(undefined);
      }, 3000);
    } catch (e) {
      handleCatch(e);
    }
  };

  const isChanging = (id: string) => (!changing ? false : changing.id === id);

  return (
    <div className="streaming-devices" ref={streamingDevicesRef}>
      <h1 className="title">Streaming Devices Management</h1>

      <h4 className="description">
        Manage stream setting and access. Control who can view specific streams. Enable email
        notifications on specific stream events.
      </h4>

      <Button className="refresh-button" onClick={refetch}>
        Refresh
      </Button>

      <CNCTable<StreamingDevice>
        className="streaming-devices-table"
        columns={streamingDevicesColumns({
          onFieldChange,
          isChanging,
          onDelete: handleOpenModal,
          isVar: user?.role === ROLES.VAR
        })}
        data={allDevices}
        loading={isFetching || loading}
        onPageChange={handlePageChange}
        onSort={handleSortChange}
      />
      {user?.role === ROLES.VAR && (
        <Button
          ref={buttonRef}
          className="streaming-devices-create-button"
          variant="primary"
          onClick={() => navigate(ROUTES.STREAMING_DEVICES_CREATE)}
        >
          Create
        </Button>
      )}
      <ConfirmModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={onDelete}
        message="Are you sure you want to delete this Device?"
      />
    </div>
  );
};

export default StreamingDevices;
