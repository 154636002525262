export const EVENT_OVERLAPPING_ERROR_MESSAGE =
  'Only three concurrent live events can be scheduled at a time. This event exceeds that allowance. Please schedule for a different time or contact CNC for additional support.';

export const DUPLICATE_USER_EMAIL_ERROR_MESSAGE =
  'Your email is already associated with a CNC account. Please try the password reset.';

export const DUPLICATE_ADMIN_EMAIL_ERROR_MESSAGE =
  'This email is already associated with a CNC account.';

export const DEFAULT_ERROR_MESSAGE = 'Something went wrong';
